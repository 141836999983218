import { User } from '@dims/components';

export interface DeepQuestionnaireTemplateNote {
  version: DeepQuestionnaireTemplateNoteVersion;
  user: User;
  date: Date;
  note: string;
}

export enum DeepQuestionnaireTemplateNoteVersion {
  Edit = 'Edit',
  Publish = 'Publish',
  Copy = 'Copy',
}
