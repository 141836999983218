<template>
  <div>
    <v-sheet v-if="wordTemplates" flat class="pa-5" color="canvas">
      <WordTemplateInfoPreview
        v-for="wordTemplate of wordTemplates"
        :key="wordTemplate.id"
        :wordTemplate
        :template
        :locator
        :showIds
      />
      <v-btn
        class="ml-4 primary-button"
        @click="downloadWordTemplates()"
      >
        Download alle Word-skabeloner
      </v-btn>
    </v-sheet>
    <LoadingSpinner :visible="isLoading" />
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import {
  DeepQuestionnaireTemplate,
  LoadingSpinner,
  downloadFile,
} from '@dims/components';
import { WordTemplate } from '@/models';
import templateServices from './templateServices';
import QuestionLocator from './Editor/QuestionLocator';
import WordTemplateInfoPreview from './WordTemplateInfoPreview.vue';

const { template, locator, showIds } = defineProps<{
  template: DeepQuestionnaireTemplate,
  locator: QuestionLocator,
  showIds: boolean,
}>();
const wordTemplates = ref<WordTemplate[] | null>(null);
const isLoading = ref(false);

async function load() {
  isLoading.value = true;
  await loadWordTemplates();
  isLoading.value = false;
}
void load();

async function loadWordTemplates() {
  wordTemplates.value = await templateServices.getWordTemplates(template);
}

async function downloadWordTemplates() {
  isLoading.value = true;
  const zip = await templateServices.downloadFiles(template.id);
  downloadFile(zip, 'Skabeloner.zip');
  isLoading.value = false;
}

</script>
