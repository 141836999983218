import { isRequirementVariant, QuestionDefinition, VariantType } from '@dims/components';
import { createBoolInput, createRepeater, createTextInput, createIntervalInput, createPricePointSetInput, dependsOn, dependsOnAll, createSection, createBoolOptionsInput } from './QuestionBuilder';
import { createQuantityDiscountQuestions } from './QuantityDiscount';

export function createVariantQuestions(variant: VariantType): QuestionDefinition[] {
  if (variant === 'pricepoint-hide-customer-values') {
    return [];
  }
  if (variant === 'pricepoint-hide-customer-question') {
    return [];
  }
  if (variant === 'pricepoint-hide-customer-question-no-intervals') {
    return [];
  }
  if (variant === 'requirement-variant-1') {
    return [];
  }
  if (variant === 'supplier-file-upload') {
    return [];
  }
  if (variant === 'supplier-quantity-discount') {
    return createQuantityDiscountQuestions();
  }
  if (variant === 'customer-pricepoint-with-repeater') {
    // Dynamic requirement - customer can add arbitrary number of requirements
    const descriptionQuestion = createTextInput('Beskrivelse', {
      name: 'description-question',
      variant: 'description-question',
    });
    descriptionQuestion.accessibleForCustomer = true;
    descriptionQuestion.accessForSupplier = 'ReadOnly';
    const intervalQuestion = createIntervalInput(
      'Antal milepæle',
      {
        unit: 'Milepæle',
        defaultValue: 1,
        minValue: 1,
        maxValue: 10,
      },
      {
        name: 'interval-question',
        variant: 'interval-question',
      },
    );
    intervalQuestion.accessibleForCustomer = true;
    intervalQuestion.accessForSupplier = 'ReadOnly';
    const pricePointSetQuestion = createPricePointSetInput(
      'Prispunkter',
      {
        configurationId: '75d51595-e7b9-4dc2-8f7e-ed334f30ce03',
        intervalsQuestionId: intervalQuestion.questionDefinitionId,
        configurationGroupsIds: ['296f988b-8a95-47df-ab4a-df29659e28d7'],
      },
      {
        name: 'pricepoint-question',
        variant: 'pricepoint-question',
      },
    );
    pricePointSetQuestion.accessibleForCustomer = true;
    pricePointSetQuestion.accessForSupplier = 'ReadWrite';
    const repeater = createRepeater('', [
      intervalQuestion,
      descriptionQuestion,
      pricePointSetQuestion,
    ], {
      required: false,
      variant: 'customer-pricepoint-repeater',
    });
    repeater.accessibleForCustomer = true;
    repeater.accessForSupplier = 'ReadOnly';
    return [repeater];
  }
  if (variant === 'requirement-variant-2') {
    const changeTextBool = createBoolInput('Skal der ændres i teksten?', { variant: 'override-texts' });
    const text = createTextInput('Skriv ny kravtekst', {
      dependsOn: dependsOn(changeTextBool),
      variant: 'custom-requirement-text',
    });
    return [changeTextBool, text];
  }
  if (variant === 'requirement-variant-3-contract') {
    /* Optional requirement - customer can remove requirement */
    const includeBool = createBoolInput('Skal kravet indgå?', { variant: 'include-requirement' });
    const changeTextBool = createBoolInput('Skal der ændres i teksten?', {
      variant: 'override-texts',
      dependsOn: dependsOn(includeBool),
    });
    const modifiedText = createTextInput('Skriv ny kravtekst', {
      dependsOn: dependsOnAll(includeBool, changeTextBool),
      variant: 'custom-requirement-text',
    });
    return [includeBool, changeTextBool, modifiedText];
  }
  if (variant === 'requirement-variant-3-evaluation') {
    /* Optional requirement - customer can remove requirement */
    const defaultEvaluationText = createSection('Evaluering', [], {
      variant: 'default-evaluation-text',
    });
    const includeBool = createBoolInput('Skal kravet indgå?', { variant: 'include-requirement' });
    const changeTextBool = createBoolInput('Skal der ændres i teksten?', {
      variant: 'override-texts',
      dependsOn: dependsOn(includeBool),
    });
    const modifiedText = createTextInput('Skriv ny kravtekst', {
      dependsOn: dependsOnAll(includeBool, changeTextBool),
      variant: 'custom-requirement-text',
    });
    const modifiedEvaluationText = createTextInput('Skriv nyt evalueringskriterie', {
      dependsOn: dependsOnAll(includeBool, changeTextBool),
      variant: 'custom-evaluation-text',
    });
    return [defaultEvaluationText, includeBool, changeTextBool, modifiedText, modifiedEvaluationText];
  }
  if (variant === 'requirement-variant-4') {
    // Dynamic requirement - customer can add arbitrary number of requirements
    const txtTitle = createTextInput('Kravoverskrift', {
      name: 'title',
      variant: 'requirement-title',
    });
    const txtText = createTextInput('Kravtekst', {
      name: 'text',
      variant: 'requirement-text',
    });
    // Hidden field
    const txtRequirementType = createTextInput('Kravtype', {
      variant: 'requirement-type',
      name: 'requirement-type',
    });
    // TODO should depend on requirement type = evaluation
    // but repeaters does not yet support item-scoped dependencies
    const customEvaluationText = createTextInput('Evalueringstekst', {
      variant: 'evaluation-text',
      name: 'evaluation-text',
    });
    const repeater = createRepeater('', [
      txtRequirementType,
      txtTitle,
      txtText,
      customEvaluationText,
    ], {
      required: false,
      variant: 'requirement4-repeater',
    });
    return [repeater];
  }
  if (variant === 'requirement-variant-5') {
    /* Optional requirement - customer can remove requirement */
    const includeRadio = createBoolOptionsInput('Skal kravet indgå?', { variant: 'include-requirement' });
    return [includeRadio];
  }
  if (variant === 'requirement-variant-6') {
    /* Optional requirement - customer can remove requirement */
    const includeBool = createBoolInput('Skal kravet indgå?', { variant: 'include-requirement' });
    const textfield = createTextInput('', {
      variant: 'requirement-textfield',
      dependsOn: dependsOn(includeBool),
    });
    return [includeBool, textfield];
  }
  if (variant === 'checkbox-list') {
    return [];
  }
  throw new Error(`Unsupported variant: '${variant}'`);
}

export function applyVariant(question: QuestionDefinition, variant: VariantType | null, agreementName?: string) {
  /* eslint-disable no-param-reassign */
  if (variant !== null) {
    question.questions = createVariantQuestions(variant);
    if (isRequirementVariant(variant)) {
      if (agreementName === '02220022') {
        // HACK, se #25447
        // In 02.22, requirements should be included in supplier questionnaire per default
        question.accessForSupplier = 'ReadOnly';
        question.questions.forEach((q) => { q.accessForSupplier = 'ReadOnly'; });
      } else {
        question.accessForSupplier = 'None';
      }
    }
  } else {
    question.questions = [];
  }
  /* eslint-enable */
}

export function labelForVariantsEnum(type: VariantType) {
  switch (type) {
    case 'checkbox-list':
      return 'Afkrydsningsliste';
    case 'requirement-variant-1':
      return 'Låst krav';
    case 'requirement-variant-2':
      return 'Tilpasset krav';
    case 'requirement-variant-3-contract':
      return 'Valgfrit kontraktkrav';
    case 'requirement-variant-3-evaluation':
      return 'Valgfrit evalueringskrav';
    case 'requirement-variant-4':
      return 'Kundens egne krav';
    case 'requirement-variant-5':
      return 'Valgfrit kontraktkrav';
    case 'requirement-variant-6':
      return 'Valgfrit krav med ekstra tekstfelt';
    case 'pricepoint-hide-customer-values':
      return 'Prispunktvariant 1';
    case 'pricepoint-hide-customer-question':
      return 'Prispunktvariant 2';
    case 'pricepoint-hide-customer-question-no-intervals':
      return 'Prispunktvariant 3';
    case 'integration-public-component-0219':
      return 'Integrationer til offentlige komponenter, registre og portaler';
    case 'integration-kombit-0219':
      return 'Krav til den fælleskommunale infrastruktur (FKI) v. KOMBIT';
    case 'calculation-unit-0219':
      return 'Beregningsenheder for deltagere i fællesindkøb';
    default:
      return 'Krav';
  }
}
