<template>
  <span class="icon-wrapper">
    <v-icon
      size="small"
      class="ml-1"
      title="Copy to clipboard"
      @click="copyToClipboard()"
    >mdi-content-copy</v-icon>
    <span v-show="copied" class="copied-message">Copied!</span>
  </span>
</template>

<script setup lang="ts">
// This component renders a copy icon that when clicked copies the content of the text prop to the browser clipboard
import { ref, onUnmounted } from 'vue';

const { text } = defineProps<{ text: string }>();

const copied = ref(false);
let timeoutId: string | number | NodeJS.Timeout | undefined | null = null;

async function copyToClipboard() {
  await navigator.clipboard.writeText(text);
  copied.value = true;
  timeoutId = setTimeout(() => {
    copied.value = false;
  }, 2000);
}

onUnmounted(() => {
  if (timeoutId) {
    clearTimeout(timeoutId);
  }
});

</script>

<style scoped>

.copied-message {
  position: absolute;
  right: -40px;
  top: -15px;
  background-color: lightgray;
  padding: 2px;
}

.icon-wrapper {
  position: relative;
}

</style>
