<template>
  <v-checkbox
    v-model="selected"
    @update:model-value="onChange"
    label="Låst felt"
    persistent-hint
    hint="Dette betyder at feltet er låst og ikke kan ændres af hverken kunde eller leverandør"
    :disabled="readonly"
  />
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';

const emit = defineEmits<{ lockedFieldSelectorChanged: [boolean | null] }>();
const { value = false, readonly = false } = defineProps<{
    value?: boolean,
    readonly?: boolean }>();
const selected = ref(false);

onMounted(() => {
  selected.value = value;
});
function onChange(newValue: boolean | null) {
  emit('lockedFieldSelectorChanged', newValue);
}

</script>
