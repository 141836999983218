import { DeepQuestionnaireTemplate, OptionDefinition, PricePointSetQuestionDefinition, QuestionDefinition, QuestionTypesEnum, VariantType } from '@dims/components';
import { labelForQuestionTypeEnum, labelForVariantsEnum } from '@/models';
import { QuestionViewModel } from '../../../models/QuestionViewModel';

/** A boolean question or a single option in an options question */
export interface DependencyViewItem {
  readonly toc: string;
  readonly questionDefinitionId: string;
  readonly displayTitle: string;
  readonly rootTitle: string;
  readonly displayPath?: string;
  readonly option: boolean;
  readonly index?: number;
  readonly question: QuestionDefinition;
}

export interface CopyViewItem {
  readonly toc: string;
  readonly questionDefinitionId: string;
  readonly questionType: string;
  readonly displayTitle: string;
  readonly displayPath: string[];
  readonly index?: number;
  readonly question: QuestionDefinition;
}

/** Various caches of relationships across a questionnaire.
 * Used to avoid n*n performance problems where each question needs to scan the entire tree.
 * Instead id's and relationships are registered in a single pass over the tree.
*/
export default class QuestionLocator {
  // Pre-calculate display title for every boolean
  readonly dependencyDisplayList: DependencyViewItem[] = [];
  // Map for quick access
  private readonly idToQuestionMap = new Map<string, QuestionDefinition>();
  // Map for quick access
  private readonly idToQuestionViewModelMap = new Map<string, QuestionViewModel>();
  // Map for quick access
  private readonly dependencyDisplayMap;
  // Pre-calculate list of items containing dependencies and price point set question used for copying questions
  readonly copyDisplayList: CopyViewItem[] = [];
  readonly copyDisplayMap;
  // interval question id to price point question
  readonly intervalToPricepoint = new Map<string, PricePointSetQuestionDefinition>();
  // reverse dependencies (map question to all question which have a dependsOn to it)
  readonly dependents = new Map<string, QuestionDefinition[]>();

  constructor(readonly template: DeepQuestionnaireTemplate) {
    // Traversing questions depth first pre-order.
    const traverse = (questions: QuestionDefinition[], path: QuestionDefinition[], toc: string, currentLevel: number, currentParent: QuestionDefinition | null) => {
      const questionCount = questions.length;
      questions.forEach((question, ix) => {
        const path1 = path.concat([question]);
        let toc1 = '';
        if (path.length > 0) {
          toc1 = toc === '' ? String(ix + 1) : `${toc}.${String(ix + 1)}`;
        }
        this.idToQuestionMap.set(question.questionDefinitionId, question);
        const questionViewModel: QuestionViewModel = {
          index: ix,
          isLast: (ix === questionCount - 1),
          level: currentLevel,
          parent: currentParent,
          precedingSibling: this.precedingSibling(questions, ix),
          followingSibling: this.followingSibling(questions, ix),
          indexInMainSection: toc1,
        };
        this.idToQuestionViewModelMap.set(question.questionDefinitionId, questionViewModel);
        this.copyDisplayList.push(this.createCopyViewItem(question, path1, toc));
        if (question.questionType === 'Boolean' || question.questionType === 'BooleanOptions') {
          const displayItem = this.displayItem(question, path1, toc1);
          this.dependencyDisplayList.push(displayItem);
        } else if (question.questionType === QuestionTypesEnum.OPTIONS) {
          question.options?.forEach((o, idx) => {
            const displayItem1 = this.displayOptionItem(question, path1, toc1, o, idx + 1);
            this.dependencyDisplayList.push(displayItem1);
          });
        } else if (question.questionType === QuestionTypesEnum.PRICEPOINTSET) {
          const intervalsQuestionId = question.pricePointSet?.intervalsQuestionId;
          if (intervalsQuestionId) {
            this.intervalToPricepoint.set(intervalsQuestionId, question);
          }
        }
        if (question.dependsOn) {
          for (const depId of question.dependsOn.operands) {
            const deps = this.dependents.get(depId) ?? [];
            this.dependents.set(depId, [...deps, question]);
          }
        }
        if (question.questions) {
          traverse(question.questions, path1, toc1, currentLevel + 1, question);
        }
      });
    };
    traverse(template.questions, [], '', 0, null);
    this.dependencyDisplayMap = new Map(this.dependencyDisplayList.map((b) => [b.questionDefinitionId, b]));
    this.copyDisplayMap = new Map(this.copyDisplayList.map((b) => [b.questionDefinitionId, b]));
  }

  getQuestion(questionId: string) {
    const questionDefinition = this.idToQuestionMap.get(questionId);
    if (!questionDefinition) throw new Error(`QuestionDefinition with id: ${questionId} was not found.`);
    return questionDefinition;
  }

  getQuestionViewModel(question: QuestionDefinition) {
    const questionViewModel = this.idToQuestionViewModelMap.get(question.questionDefinitionId);
    return questionViewModel ?? null;
  }

  private precedingSibling(questions: QuestionDefinition[], index: number) {
    if (index === 0) return null;
    return questions[index - 1] ?? null;
  }

  followingSibling(questions: QuestionDefinition[], index: number) {
    if (index === questions.length - 1) return null;
    return questions[index + 1] ?? null;
  }

  private displayItem(question: QuestionDefinition, path: QuestionDefinition[], toc: string) {
    const root = path[0];
    if (!root) {
      throw new Error('Root question definition not found');
    }
    // In some instances, the question title is very generic, so we need to include the parent question title to make it usable
    const parentTitle = path.length > 2 ? path[path.length - 2]?.title : '';
    const displayTitle = `${root.title}: ${toc} ${parentTitle} ${question.title}`;
    let displayPath = '';
    path.forEach((q) => {
      displayPath = `${displayPath ? `${displayPath} >` : displayPath} ${q.title}`;
    });
    return {
      toc,
      displayTitle,
      rootTitle: root.title,
      displayPath,
      questionDefinitionId: question.questionDefinitionId,
      option: false,
      question,
    };
  }

  private displayOptionItem(question: QuestionDefinition, path: QuestionDefinition[], toc: string, option: OptionDefinition, index: number) {
    const root = path[0];
    if (!root) {
      throw new Error('Root question definition not found');
    }
    const displayTitle = `${root.title}: ${toc} ${question.title} - ${option.text}`;
    let displayPath = '';
    path.forEach((q) => {
      displayPath = `${displayPath ? `${displayPath} >` : displayPath} ${q.title} - ${option.text}`;
    });
    return {
      toc,
      displayTitle,
      rootTitle: root.title,
      displayPath,
      questionDefinitionId: option.optionId,
      option: true,
      index,
      question,
    };
  }

  /**
   * Copies the given question and associated data to create a new CopyViewItem.
   *
   * @param {QuestionDefinition} question - The question to be copied.
   * @param {QuestionDefinition[]} path - The path of questions leading to the current question.
   * @param {string} toc - The table of contents identifier.
   * @param {DeepQuestionnaireTemplate} template - The template containing the questions.
   * @returns {CopyViewItem} - The copied question and associated data.
   */
  private createCopyViewItem(
    question: QuestionDefinition,
    path: QuestionDefinition[],
    toc: string,
  ): CopyViewItem {
    const displayTitle = `${toc.length ? `${toc}:` : ''} ${question.title}`;
    const displayPath = path.map((q) => q.title);
    const type = `${labelForQuestionTypeEnum(question.questionType)} ${
      question.variant ? ` / ${labelForVariantsEnum(question.variant as VariantType)}` : ''
    }`;

    return {
      toc,
      displayTitle,
      displayPath,
      questionDefinitionId: question.questionDefinitionId,
      questionType: type,
      question,
    };
  }

  /** Reverse dependencies: Get all questions which have a dependsOn to the given question */
  getDependents(questionDefinitionId: string): QuestionDefinition[] {
    return this.dependents.get(questionDefinitionId) ?? [];
  }

  dependencyDisplayById(id: string) {
    return this.dependencyDisplayMap.get(id);
  }
}
