import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import LuxonAdapter from '@date-io/luxon';
import { da } from 'vuetify/locale';

import 'vuetify/dist/vuetify.min.css';

const luxon = new LuxonAdapter({ locale: 'da' });

export default createVuetify({
  locale: {
    locale: 'da',
    fallback: 'en',
    messages: { da },
  },
  date: {
    adapter: luxon,
  },
  components,
  directives,
  theme: {
    themes: {
      light: {
        colors: {
          primary: '#63756B',
          secondary: '#829189',
          canvas: '#F4F6F5', // <- Samme som light grey
          lightPrimary: '#ABB5AF',
          accent: '#C1476E',
          accentDark: '#802F49',
          error: '#FE6A6A',
          info: '#2196f3',
          success: '#82BD7D',
          warning: '#FBDB67',
          // NYE FARVER
          skiGreen: '#63756B',
          darkGreen: '#49574F',
          pastelGreen: '#F4FBF7',
          cherry: '#C1476E',
          plum: '#802F49',
          pastelPink: '#FFF3F7',
          lightPurple: '#6F728C',
          darkPurple: '#54546E',
          pastelPurple: '#F5F5FC',
          skiGrey: '#E6ECE9',
          lightGrey: '#F4F6F5', // OBS! Den grå farve "light grey" bliver justeret på ski.dk meget snart
          // INFOGRAFIK Farver
          lightGreen: '#93AD9F',
          bordeaux: '#421825',
          sand: '#F6DB9F',
          deepPurple: '#34344A',
        },
      },
      dark: {
        colors: {
          primary: '#63756b',
          secondary: '#829189',
          accent: '#c1476e',
          error: '#ff5252',
          info: '#2196f3',
          success: '#4caf50',
          warning: '#ffc107',
        },
      },
    },
  },
});
