<template>
  <v-dialog :modelValue="exportDialog" width="600">
    <template #activator="{ props }">
      <v-btn class="secondary-button-mini ml-2" v-bind="props" variant="flat"><v-icon>mdi-content-duplicate</v-icon>Kopiér til...</v-btn>
    </template>
    <template #default="{ isActive }">
      <v-card>
        <v-card-text>
          <p>Aktuelt miljø: {{ environment === 'Dev' ? 'Beta (dev)' : environment }} </p>
          <div class="button-row">
            <v-btn v-if="environment === 'Demo'" @click="startCopyTo('Prod')" variant="flat" class="secondary-button"><v-icon>mdi-content-duplicate</v-icon>Kopier til Produktion</v-btn>
            <v-btn v-if="environment === 'Prod'" @click="startCopyTo('Demo')" variant="flat" class="secondary-button"><v-icon>mdi-content-duplicate</v-icon>Kopier til Demo</v-btn>
            <!-- Copy to Dev is available in Dev and Copy to Local is available in Local, to make it possible to test the copy functionality.  -->
            <v-btn v-if="environment !== 'Local'" @click="startCopyTo('Dev')" variant="flat" class="secondary-button"><v-icon>mdi-content-duplicate</v-icon>Kopier til Beta</v-btn>
            <v-btn v-if="environment === 'Local'" @click="startCopyTo('Local')" variant="flat" class="secondary-button"><v-icon>mdi-content-duplicate</v-icon>Kopier til Local</v-btn>
            <v-btn @click="exportToPackage()" variant="flat" class="secondary-button"><v-icon>mdi-download</v-icon>Download som pakke</v-btn>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="secondary-button" @click="isActive.value = false">Luk</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
      <v-dialog :modelValue="confirmDialog" width="600">
        <v-card>
          <v-card-text>
            <p>Vil du overskrive tilsvarende version på {{ targetEnvironment }}?</p>
            <p>Vær opmærksom på at hvis formular med samme ID allerede eksisterer på miljøet vil det blive overskrevet, og eventuelle indkøb vil blive ugyldige.</p>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="cancelConfirm()" class="secondary-button">Nej, fortryd.</v-btn>
            <v-btn @click="confirmCopy()" class="primary-button">Ja, kopier</v-btn>
          </v-card-actions>
        </v-card>
        <LoadingSpinner :visible="processing" />
      </v-dialog>
      <v-dialog :modelValue="resultDialog" width="400">
        <v-card>
          <v-card-text>
            {{ response?.status }}
          </v-card-text>
          <v-card-actions>
            <v-btn @click="resultDialog = false" class="primary-button">Luk</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { downloadFile, LoadingSpinner, useConfigStore } from '@dims/components';
import templateServices from '@/components/Templates/templateServices';
import { DeepQuestionnaireTemplateSummary } from '@/components/Templates/DeepQuestionnaireTemplateSummary';

type Env = 'Dev' | 'Demo' | 'Local' | 'Prod';

const { templateId } = defineProps<{ templateId: string }>();
const exportDialog = ref(false);
const confirmDialog = ref(false);
const resultDialog = ref(false);
const processing = ref(false);
const targetEnvironment = ref<Env>();
const response = ref<{ status: string }>();

function cancelConfirm() {
  confirmDialog.value = false;
  targetEnvironment.value = undefined;
}

const environment = (useConfigStore().configuration.environment || 'Prod') as Env;

function startCopyTo(selectedTargetEnvironment: Env) {
  targetEnvironment.value = selectedTargetEnvironment;
  confirmDialog.value = true;
}

async function confirmCopy() {
  if (targetEnvironment.value) {
    processing.value = true;
    try {
      response.value = await templateServices.copyToEnvironment(templateId, targetEnvironment.value);
      exportDialog.value = false;
      resultDialog.value = true;
    } finally {
      confirmDialog.value = false;
      processing.value = false;
    }
  }
}

async function exportToPackage(template?: DeepQuestionnaireTemplateSummary) {
  if (template) {
    const blob = await templateServices.exportToPackage(template.id);
    downloadFile(blob, 'package.zip');
  }
}

</script>

<style scoped>
.button-row {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
</style>
