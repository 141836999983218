<template>
  <LoggedInViewBase @authenticated="authenticated" />
</template>

<script setup lang="ts">
import { LoggedInViewBase } from '@dims/components';
import { useRouter } from 'vue-router';
import { useStore } from '@/store/store';

const router = useRouter();
const store = useStore();

async function authenticated() {
  console.log('AUTH');
  await store.initialize();
  if (store.isAdmin) {
    await router.push({ path: '/agreements' });
  } else {
    throw new Error('Bruger har ikke administratoradgang.');
  }
}
</script>
