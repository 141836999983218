<template>
  <v-icon
    @click="toggle()"
    class="mr-2"
    :class="{ 'text-white': isRoot }"
    :style="{ opacity: isVisible ? 1 : 0 }"
  >{{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { QuestionDefinition } from '@dims/components';
import ExpandedStateKeeper from './ExpandedStateKeeper';
import { isQuestionAccessEditable } from '@/utils';

const { expandedStateKeeper, isRoot = false, question } = defineProps<{
  expandedStateKeeper: ExpandedStateKeeper,
  isRoot?: boolean,
  question: QuestionDefinition }>();

const hasChildren = computed(() => question.questions && question.questions.length > 0);

const isVisible = computed(() => hasChildren.value && isQuestionAccessEditable(question));

const isExpanded = computed(() => expandedStateKeeper.getIsExpanded(question.questionDefinitionId));

function toggle() {
  if (isVisible.value) {
    expandedStateKeeper.toggleIsExpanded(question.questionDefinitionId);
  }
}

</script>
