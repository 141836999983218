import { QuestionDefinition, QuestionTypesEnum } from '@dims/components';
import _ from 'lodash';
import { v4 as newUUID } from 'uuid';
import { isPricePointSet } from '@/components/Templates/utilities';

/** Clones a question including subquestions.
 *
 * - New Guids to ensure uniqueness:
 *    - Sets questionDefinitionId and name of the cloned question and all subquestions to a new unique guid
 *      (Special case for for variant-4 repeaters, inside which name should not be changed)
 *    - Generates new option IDs for questions of type OPTIONS
 * - If a cloned question have a dependency to another question/option which is also cloned, we update the reference to the cloned.
 *    - also updates the intervalsQuestionId to the cloned intervals question (if it exists) for price point set questions
 */
export default function cloneQuestion(originalQuestion: QuestionDefinition) {
  const questionOrOptionIdMapping = new Map<string, string>();

  const clonedRoot = _.cloneDeep(originalQuestion);

  function clearKeyOnQuestionAndDescendants(question: QuestionDefinition) {
    const q = question;
    q.key = undefined;
    q.questions?.forEach((subQuestion) => { clearKeyOnQuestionAndDescendants(subQuestion); });
  }

  /** Updates ID and name to new guids. */
  function updateIDsInClonedQuestion(question: QuestionDefinition) {
    const newId = newUUID();

    const updatedQuestion = question;

    // Update option IDs and store mappings if the question is of type OPTIONS
    if (updatedQuestion.questionType === QuestionTypesEnum.OPTIONS && updatedQuestion.options) {
      for (const o of updatedQuestion.options) {
        const newOptionId = newUUID();

        // Store mapping between old and new option IDs
        questionOrOptionIdMapping.set(o.optionId, newOptionId);

        // Update option ID
        o.optionId = newOptionId;
      }
    }

    // Register mapping between old and new question IDs
    questionOrOptionIdMapping.set(updatedQuestion.questionDefinitionId, newId);
    updatedQuestion.questionDefinitionId = newId;
    updatedQuestion.name = newId;
    // Update recursively
    for (const q of updatedQuestion.questions ?? []) {
      updateIDsInClonedQuestion(q);
    }
  }

  /** If the ID is to a question which have been cloned, return the ID of the cloned version. Otherwise the original ID */
  function translatedId(id: string) {
    return questionOrOptionIdMapping.get(id) ?? id;
  }

  /** If any of the cloned question have a dependency to another question/option which is also cloned, we update the reference to the cloned
   * If a question is a price point set question, we also update the intervalsQuestionId to the cloned intervals question (if it exists)
  */
  function updateInternalDependencies(question: QuestionDefinition) {
    if (question.dependsOn?.operands) {
      // eslint-disable-next-line no-param-reassign
      question.dependsOn.operands = question.dependsOn.operands.map((id) => translatedId(id));
    }
    if (isPricePointSet(question) && question.pricePointSet?.intervalsQuestionId) {
      // eslint-disable-next-line no-param-reassign
      question.pricePointSet.intervalsQuestionId = translatedId(question.pricePointSet.intervalsQuestionId);
    }
    // Update recursive
    question.questions?.forEach(updateInternalDependencies);
  }

  // Prevent duplicate keys.
  clearKeyOnQuestionAndDescendants(clonedRoot);
  // First pass gives new IDs to all questions and options
  updateIDsInClonedQuestion(clonedRoot);
  // Second pass updates internal dependencies
  updateInternalDependencies(clonedRoot);
  return clonedRoot;
}
