<template>
  <v-dialog width="90%" v-model="visible">
    <v-card v-if="readonly" :title="getReadOnlyTitle()">
      <v-card-text v-if="publishedNote">
        <div class="scrollable-text scrollable-text-small">
          <h3>Release note</h3>
          <p v-if="publishedNote.note && publishedNote.note.length > 0">
            <b>{{ publishedNote.user ? publishedNote.user.fullName : "Ukendt bruger" }}, {{ publishedNote.date }}</b><br>{{ publishedNote.note }}
          </p>
          <p v-else>Ingen release note</p>
        </div>
      </v-card-text>
      <v-card-text>
        <h3>Andre noter</h3>
        <div v-if="otherNotes && otherNotes.length > 0" class="scrollable-text scrollable-text-big">
          <div v-for="(item, index) in otherNotes" :key="index">
            <p>
              <b>{{ item.user ? item.user.fullName : "Ukendt bruger" }}, {{ item.date }}</b><br>{{ item.note }}
            </p>
          </div>
        </div>
        <p v-else>Ingen andre noter</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="visible = false"
          class="primary-button"
        >
          Luk
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <v-card title="Tilføj note" v-else>
      <v-card-text>
        <h3>Tidligere noter</h3>
        <v-for v-if="existingNotes && existingNotes.length > 0">
          <div v-for="(item, index) in existingNotes" :key="index" class="scrollable-text scrollable-text-small">
            <p>
              <b>{{ item.user ? item.user.fullName : "Ukendt bruger" }}, {{ item.date }}</b><br>{{ item.note }}
            </p>
          </div>
        </v-for>
        <p v-else>Ingen tidligere noter</p>
        <h3>Ny note</h3>
        <v-textarea
          v-model="editNote"
          variant="outlined"
          density="compact"
          class="monospace"
        >
        </v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="commitEdit()"
          class="primary-button"
        >
          Gem
        </v-btn>
        <v-btn
          @click="emit('closeDialog', false)"
          class="secondary-button"
        >
          Annuller
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <LoadingSpinner :visible="isLoading" />
  </v-dialog>
</template>

<script setup lang="ts">

import { computed, ref, nextTick } from 'vue';
import { LoadingSpinner, DeepQuestionnaireTemplate } from '@dims/components';
import { DeepQuestionnaireTemplateNote, DeepQuestionnaireTemplateNoteVersion } from '@/models/DeepQuestionnaireTemplateNote';
import templateServices from '@/components/Templates/templateServices';

const emit = defineEmits<{
  closeDialog: [boolean],
}>();

const { readonly, template, versionNumber } = defineProps<{
  readonly: boolean;
  template: DeepQuestionnaireTemplate;
  versionNumber?: number | undefined;
}>();

const editNote = ref('');
const isLoading = ref(false);
const visible = defineModel<boolean>();
const existingNotes = ref<DeepQuestionnaireTemplateNote[] | null>(null);
const earlierNotes = ref(null as HTMLElement | null);

const publishedNote = computed((): DeepQuestionnaireTemplateNote | undefined => existingNotes.value?.find((note) => note.version === DeepQuestionnaireTemplateNoteVersion.Publish));

const otherNotes = computed((): DeepQuestionnaireTemplateNote[] | undefined => existingNotes.value?.filter((note) => note.version !== DeepQuestionnaireTemplateNoteVersion.Publish));

function getReadOnlyTitle() {
  return `Release note for version ${versionNumber} (${template.id})`;
}

async function load() {
  isLoading.value = true;

  existingNotes.value = await templateServices.getTemplateNotes(template.id);

  isLoading.value = false;

  await nextTick(() => {
    if (earlierNotes.value) {
      earlierNotes.value.scrollTop = earlierNotes.value.scrollHeight;
    }
  });
}
void load();

async function commitEdit() {
  isLoading.value = true;

  await templateServices.addTemplateNote(template.id, editNote.value);

  isLoading.value = false;

  emit('closeDialog', true);
}

</script>

<style scoped>

.scrollable-text {
  overflow-y: auto;
  padding-right: 10px;
  margin-bottom: 20px;
  white-space: pre-wrap;
}

.scrollable-text-small {
  max-height: 300px;
}

.scrollable-text-big {
  max-height: 600px;
}

</style>
