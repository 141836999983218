import { useBackend } from '@dims/components';
import { AgreementConfigurationSummary } from './models/Agreement';

// Consider moving this to DimsComponents, or just using  GetAgreementConfiguration method
export default {
  getApi() {
    const backend = useBackend();
    const { userService } = backend;
    return userService.getAuthorizedInstance();
  },
  async getAgreements() {
    const response = await this.getApi().get<AgreementConfigurationSummary[]>(
      'agreements',
    );
    return response.data;
  },
};
