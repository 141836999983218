import '@dims/components/dist/dims-components.css';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { ApplicationInsights, IExceptionTelemetry } from '@microsoft/applicationinsights-web';
import { AuthenticationError, fetchConfiguration, useConfigStore, useErrorStore } from '@dims/components';
import App from '@/App.vue';
import router from '@/router';
import vuetify from '@/plugins/vuetify';

fetchConfiguration().then((configuration) => {
  // App insights might not be configured for all environments
  let appInsights: ApplicationInsights | undefined;
  if (configuration.appAIConnectionString) {
    appInsights = new ApplicationInsights({
      config: {
        connectionString: configuration.appAIConnectionString,
        enableAutoRouteTracking: true,
        disableFetchTracking: false,
        enableCorsCorrelation: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
      },
    });
    appInsights.loadAppInsights();
  }
  window.addEventListener('unhandledrejection', (event: PromiseRejectionEvent) => {
    const error: unknown = event.reason;
    if (appInsights) {
      appInsights.trackException(
        {
          exception: event.reason as Error,
        } satisfies IExceptionTelemetry,
      );
    }
    const errorStore = useErrorStore();
    errorStore.handleUncaughtError(error);
    event.preventDefault();
  });

  const pinia = createPinia();

  const app = createApp(App)
    .use(pinia)
    .use(() => {
      // Pinia stores can be initialized only after Vue is created
      useConfigStore().setConfiguration(configuration, { agreementId: '', agreementNameLong: '' }, null);
    })
    .use(router)
    .use(vuetify);
  /*
  // Only triggered in dev mode. Template-related warnings.
  app.config.warnHandler = (msg, instance, trace) => {
    // `trace` is the component hierarchy trace
    console.warn(msg);
    console.warn(instance);
    console.warn(trace);
    throw new Error(msg);
  };
  */
  app.config.errorHandler = (msg, instance, trace) => {
    console.debug('app.config.errorHandler', msg);
    /*
    appInsights.trackException(
      {
        exception: msg as Error,
      } satisfies IExceptionTelemetry,
    );
    */
    console.error(msg);
    console.error(instance);
    console.error(trace);
    const errorStore = useErrorStore();
    if (!(msg instanceof AuthenticationError)) {
      errorStore.handleUncaughtError(msg);
    }
  };

  // https://www.vuemastery.com/blog/vue-router-4-route-params-not-available-on-created-setup/
  router.isReady().then(() => {
    app.mount('#app');
  }).catch((err: unknown) => { console.error('Failed to initialize app', err); });
}, (rejected: unknown) => {
  console.error('Cannot fetch site config.json', rejected);
});
