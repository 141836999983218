import { NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw, createRouter, createWebHistory } from 'vue-router';
import { LoginView, LoggedOutView, useBackend } from '@dims/components';
import HomeView from '@/views/HomeView.vue';
import TemplateList from '@/views/TemplateList.vue';
import TemplateEditor from '@/components/Templates/Editor/TemplateEditor.vue';
import PathNotFound from '@/views/PathNotFound.vue';
import LoggedInView from '@/views/LoggedInView.vue';
import AgreementList from '@/views/AgreementList.vue';
import Tools from '@/views/ToolsView.vue';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'Hjem',
    meta: { allowAnonymous: true },
    component: HomeView,
  },
  {
    path: '/login',
    name: 'Login',
    meta: { allowAnonymous: true },
    component: LoginView,
  },
  {
    path: '/loggedOut',
    name: 'Loggedout',
    meta: { allowAnonymous: true },
    component: LoggedOutView,
  },
  {
    path: '/loggedIn',
    name: 'LoggedIn',
    meta: { allowAnonymous: true },
    component: LoggedInView,
  },
  {
    path: '/agreements',
    name: 'Aftaler',
    component: AgreementList,
  },
  {
    path: '/agreement/:agreementName',
    name: 'Agreement',
    component: TemplateList,
    props: true,
  },
  {
    path: '/template/:templateId',
    name: 'Skabelon',
    props: true,
    component: TemplateEditor,
  },
  {
    path: '/tools',
    name: 'Tools',
    component: () => Tools,
  },
  {
    path: '/:catchAll(.*)',
    name: '404 Not Found',
    component: PathNotFound,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const requireAuth = async (to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext) => {
  console.log('requireAuth', to);
  const backend = useBackend();
  const user = await backend.userService.getUser();
  if (to.meta.allowAnonymous !== true && user === null) {
    console.log('requireAuth: Not logged in but should be');
    next({ name: 'Login' });
  } else {
    next();
  }
};

router.beforeEach(requireAuth);

export default router;
