<template>
  <v-dialog
    v-model="dialog"
    scrollable
  >
    <template #activator="{ props }">
      <v-btn v-bind="props" class="secondary-button-mini mr-2">
        <v-icon size="small" class="mr-2">mdi-graph-outline</v-icon>
        Betingelser
      </v-btn>
    </template>
    <v-card color="canvas">
      <template #title>
        <h3 class="mt-0">Betingelser</h3>
      </template>
      <template #append>
        <v-btn icon="mdi-close" @click="dialog = false" />
      </template>
      <v-card-text class="mt-4">
        <v-expansion-panels>
          <v-expansion-panel v-for="q in questionsWithDependencies" :key="q.questionDefinitionId">
            <v-expansion-panel-title>
              <div class="d-flex align-center">
                <div>{{ q.displayTitle }}</div>
                <v-chip size="small" class="mx-2">{{ q.dependencies?.length }}</v-chip>
                <small>({{ q.questionDefinitionId }})</small>
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <div v-for="dependency in q.dependencies || []" :key="dependency.questionDefinitionId">
                <v-divider></v-divider>
                <div class="d-flex">
                  <v-icon color="accent">mdi-square-medium</v-icon>
                  <div class="ml-2">
                    <div class="d-flex align-center">
                      <div class="text-accent">{{ dependency.displayTitle }}</div>
                      <small class="ml-1">({{ dependency.questionDefinitionId }})</small>
                    </div>
                    <QuestionPath v-if="dependency.displayPath" :path="dependency.displayPath" />
                  </div>
                </div>
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import QuestionLocator, { CopyViewItem } from '../QuestionLocator';
import QuestionPath from './CopyQuestion/QuestionPath.vue';

interface DisplayItem extends CopyViewItem {
  readonly dependencies: CopyViewItem[]
}

const { locator } = defineProps<{ locator: QuestionLocator }>();
const dialog = ref(false);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const questionsWithDependencies = ref<DisplayItem[]>(null!);

onMounted(() => {
  const displayMap = new Map(locator.copyDisplayList.map((b) => [b.questionDefinitionId, b]));
  questionsWithDependencies.value = locator.copyDisplayList
    .map((d) => addDependents(d, displayMap))
    .filter((c) => c.dependencies.length);
});

function getDependents(questionDefinitionId: string, map: Map<string, CopyViewItem>): CopyViewItem[] {
  return locator.getDependents(questionDefinitionId)
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    .map((d) => map.get(d.questionDefinitionId)!.questionDefinitionId)
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    .map((id) => locator.copyDisplayMap.get(id)!);
}

function addDependents(item: CopyViewItem, map: Map<string, CopyViewItem>): DisplayItem {
  return {
    ...item,
    dependencies: getDependents(item.questionDefinitionId, map),
  };
}

</script>
