<template>
  <v-dialog v-model="model" max-width="600px">
    <TemplateAlert
      :showAlert
      text="Spørgsmålet du er ved at slette har betingelser. Hvis du sletter spørgsmålet, vil betingelserne også blive slettet."
      type="warning"
    >
    </TemplateAlert>
    <v-card v-if="question" class="pa-5">
      <v-card-text class="text-center">
        <h2 class="mt-0">
          Er du sikker på at du vil slette
          <span class="font-weight-bold">{{ question.title }}</span>?
        </h2>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="secondary-button" @click="model = false">
          Annuller
        </v-btn>
        <v-btn class="primary-button" @click="deleteQuestion()"> Ja </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { QuestionDefinition } from '@dims/components';
import TemplateAlert from './TemplateAlert.vue';

const emit = defineEmits<{ deleteQuestion: [questionDefinitionId: string] }>();
const { question } = defineProps<{ question: QuestionDefinition }>();
const model = defineModel<boolean>();

const showAlert = computed(() => {
  if (question.dependsOn) {
    return true;
  }

  return false;
});

function deleteQuestion() {
  emit('deleteQuestion', question.questionDefinitionId);
  model.value = false;
}

</script>
