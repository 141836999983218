<template>
  <div v-if="intervals">
    <v-text-field
      v-model="intervals.unit"
      :disabled="readonly"
      density="compact"
      variant="outlined"
      label="Enhed (f.eks år / milepæl)"
    ></v-text-field>
    <v-row>
      <v-col>
        <NumericInput
          v-model="intervals.defaultValue"
          :disabled="readonly"
          variant="outlined"
          label="Default værdi"
        />
      </v-col>
      <v-col>
        <NumericInput
          v-model="intervals.minValue"
          :disabled="readonly"
          variant="outlined"
          label="Min"
        />
      </v-col>
      <v-col>
        <NumericInput
          v-model="intervals.maxValue"
          :disabled="readonly"
          variant="outlined"
          label="Max"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script setup lang="ts">
import { IntervalsQuestionDefinition, NumericInput } from '@dims/components';
import { computed } from 'vue';

const { question, readonly = false } = defineProps<{
  question: IntervalsQuestionDefinition,
  readonly?: boolean,
}>();

const intervals = computed(() => question.intervals);

</script>
