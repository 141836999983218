<template>
  <v-data-table
    class="elevation-1 my-5"
    :headers
    :items="options"
    no-data-text="Ingen valgmuligheder"
    no-results-text="Ingen valgmuligheder"
    :items-per-page-options="[5, 10]"
  >
    <template #top>
      <v-toolbar flat color="canvas" class="px-2">
        Valgmuligheder
        <v-chip v-if="options" class="mx-2" size="small">{{ options.length }}</v-chip>
        <v-row class="align-center ml-2">
          <v-col>
            <v-text-field
              v-model="text"
              label="Indsæt tekst"
              hide-details
              variant="outlined"
              density="compact"
              :disabled="readonly"
            ></v-text-field>
          </v-col>
          <v-col cols="auto">
            <v-btn
              @click="addOption()"
              class="primary-button-mini"
              :disabled="!text || readonly"
              data-cy="addoptionbtn"
            >Tilføj</v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
    </template>
    <template #[`item.actions`]="{ item, index }">
      <v-btn
        @click="deleteOption(item)"
        class="float-right"
        :disabled="readonly"
        icon="mdi-delete"
      />
      <v-btn
        @click="openEditOption(item)"
        class="float-right"
        :disabled="readonly"
        icon="mdi-pencil"
      />
      <v-btn
        variant="text"
        @click="moveUp(index)"
        class="float-right"
        :disabled="(index === 0) || readonly"
        icon="mdi-arrow-up"
      />
      <v-btn
        variant="text"
        @click="moveDown(index)"
        class="float-right"
        :disabled="(index === options.length - 1) || readonly"
        icon="mdi-arrow-down"
      />
    </template>
  </v-data-table>
  <v-dialog v-if="editingOption" v-model="showEditDialog" max-width="600px">
    <v-form v-model="isOptionFormValid">
      <v-card>
        <v-card-title>Rediger valgmulighed</v-card-title>
        <v-card-text>
          <v-text-field v-model="editingOption.text" label="Tekst" />
          <v-text-field
            v-model="editingOption.key"
            label="Backend nøgle"
            hint="Kobling til forretningslogik"
            class="monospace"
            :rules="[keyValidation]"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn @click="closeEditOption">Annuller</v-btn>
          <v-btn @click="commitEditedOption" :disabled="!isOptionFormValid">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script setup lang="ts">
import { OptionDefinition, OptionsQuestionDefinition } from '@dims/components';
import { computed, ref } from 'vue';
import { v4 as newUUID } from 'uuid';
import keyValidation from './keyValidation';

const emit = defineEmits<{ optionsChange: [OptionDefinition[]] }>();
const { question, readonly = false } = defineProps<{
  question: OptionsQuestionDefinition,
  readonly?: boolean,
}>();
const editingOption = ref<OptionDefinition | null>(null);
const showEditDialog = ref(false);
const isOptionFormValid = ref(false);

const options = computed(() => question.options ?? []);

const text = ref('');
const headers = [
  {
    title: 'Titel',
    value: 'text',
  },
  {
    title: 'ID',
    value: 'optionId',
  },
  {
    title: 'Nøgle',
    value: 'key',
  },
  {
    title: '',
    value: 'actions',
  },
];

function addOption() {
  const option: OptionDefinition = {
    optionId: newUUID(),
    text: text.value,
  };
  emit('optionsChange', [...options.value, option]);
  text.value = '';
}

function deleteOption(option: OptionDefinition) {
  emit(
    'optionsChange',
    options.value.filter((o) => o.optionId !== option.optionId),
  );
}

function openEditOption(option: OptionDefinition) {
  editingOption.value = { ...option };
  showEditDialog.value = true;
}

function commitEditedOption() {
  if (editingOption.value) {
    emit(
      'optionsChange',
      options.value.map((o) => {
        if (o.optionId === editingOption.value?.optionId) {
          return { ...editingOption.value };
        }
        return o;
      }),
    );
  }
  closeEditOption();
}

function closeEditOption() {
  editingOption.value = null;
  showEditDialog.value = false;
}

const moveUp = (index: number) => {
  if (index > 0) {
    const temp = { ...options.value[index] };
    const previous = { ...options.value[index - 1] };
    options.value[index] = previous as OptionDefinition;
    options.value[index - 1] = temp as OptionDefinition;
    emit('optionsChange', options.value);
  }
};

const moveDown = (index: number) => {
  if (index < options.value.length - 1) {
    const temp = { ...options.value[index] };
    const next = { ...options.value[index + 1] };
    options.value[index] = next as OptionDefinition;
    options.value[index + 1] = temp as OptionDefinition;
    emit('optionsChange', options.value);
  }
};

</script>
