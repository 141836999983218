<template>
  <v-data-table
    v-if="items.length > 0"
    :headers
    :items
    class="elevation-1 my-5"
    no-data-text="Ingen betingelser"
    no-results-text="Ingen betingelser"
    :hide-default-footer="true"
  >
    <template #top>
      <v-toolbar flat color="canvas" class="pl-5" density="compact">
        Betingelser
        <v-chip class="mx-2" size="small">{{ operands.length }}</v-chip>
        <div v-if="condition && condition.operands.length > 1">
          <v-chip size="small" v-if="condition.operator === 'Or'">En af</v-chip>
          <v-chip size="small" v-if="condition.operator === 'And'">Alle</v-chip>
        </div>
        <v-spacer></v-spacer>
        <v-btn
          title="Slet alle betingelser"
          :disabled="readonly"
          v-if="operands.length"
          icon
          @click="dialogDelete = true"
        ><v-icon>mdi-delete</v-icon></v-btn>
        <DependenciesSelect
          v-if="!readonly"
          :condition
          :locator
          @addDependencies="addDependencies"
          :currentQuestion
        />
      </v-toolbar>
    </template>
  </v-data-table>
  <!-- If no dependencies: -->
  <v-toolbar v-else flat class="my-5 pl-5" color="canvas" density="compact">
    Betingelser
    <v-chip class="mx-2" size="small">{{ operands.length }}</v-chip>
    <v-spacer></v-spacer>
    <DependenciesSelect
      v-if="!readonly"
      :condition
      :locator
      @addDependencies="addDependencies"
      :currentQuestion
    />
  </v-toolbar>

  <v-dialog v-model="dialogDelete" max-width="600px">
    <v-card class="pa-5">
      <v-card-text class="text-center">
        <h2 class="mt-0">
          Er du sikker på at du vil slette alle betingelser?
        </h2>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="secondary-button" variant="text" @click="dialogDelete = false">
          Annuller
        </v-btn>
        <v-btn class="primary-button" variant="text" @click="deleteAllDependencies()">
          Slet
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import {
  Condition,
  QuestionDefinition,
} from '@dims/components';
import { ref, computed } from 'vue';
import DependenciesSelect from './DependenciesSelect.vue';
import QuestionLocator, { DependencyViewItem } from '../QuestionLocator';

const emit = defineEmits<{ conditionChange: [Condition | undefined] }>();
const { currentQuestion = null, condition, locator, readonly = false } = defineProps<{
  currentQuestion?: QuestionDefinition | null,
  condition: Condition | undefined,
  locator: QuestionLocator,
  readonly?: boolean,
}>();
const dialogDelete = ref(false);
const headers = [
  {
    title: 'Titel',
    value: 'title',
    sort: (a: string, b: string) => (a > b ? -1 : 1),
    sortable: false,
  },
  {
    title: 'TOC',
    value: 'toc',
    sort: (a: string, b: string) => (a > b ? -1 : 1),
    sortable: false,
  },
  {
    title: 'ID',
    value: 'questionDefinitionId',
  },
];

const operands = computed(() => condition?.operands ?? []);

const items = computed(() => operands.value.map((o) => {
  const dependencyDisplay = locator.dependencyDisplayById(o);
  return {
    title: dependencyDisplay?.displayTitle,
    toc: dependencyDisplay?.toc,
    questionDefinitionId: o,
  };
}));

function addDependencies(questions: DependencyViewItem[], operator: 'And' | 'Or' | 'Not') {
  let newCondition;
  if (questions.length > 0) {
    newCondition = {
      operator,
      operands: questions.map((q) => q.questionDefinitionId),
    };
  } else {
    newCondition = undefined;
  }
  emit('conditionChange', newCondition);
}

function deleteAllDependencies() {
  emit('conditionChange', undefined);
  dialogDelete.value = false;
}

</script>
