import { QuestionDefinition } from '@dims/components';

// Used to keep track of isExpanded state of questions.
// Initialized from TemplateEditor with all questions collapsed.
export default class ExpandedStateKeeper {
  readonly idToIsExpandedMap = new Map<string, boolean>();

  // Create from template questions and initialize isExpanded.
  constructor(templateQuestions: QuestionDefinition[], initialIsExpanded: boolean) {
    const setIsExpandedRecursive = (questions: QuestionDefinition[], isExpanded: boolean) => {
      questions.forEach((questionDefinition) => {
        this.idToIsExpandedMap.set(questionDefinition.questionDefinitionId, isExpanded);
        if (questionDefinition.questions && questionDefinition.questions.length > 0) {
          setIsExpandedRecursive(questionDefinition.questions, isExpanded);
        }
      });
    };

    setIsExpandedRecursive(templateQuestions, initialIsExpanded);
  }

  getIsExpanded(questionId: string) {
    const isExpanded = this.idToIsExpandedMap.get(questionId);
    if (isExpanded === undefined) {
      console.error(`isExpanded for question with id: ${questionId} was not found.`);
      return false;
    }
    return isExpanded;
  }

  // Add new or update existing.
  setIsExpanded(questionId: string, isExpanded: boolean) {
    this.idToIsExpandedMap.set(questionId, isExpanded);
  }

  toggleIsExpanded(questionId: string) {
    const currentState = this.getIsExpanded(questionId);
    this.setIsExpanded(questionId, !currentState);
  }

  deleteIsExpanded(questionId: string) {
    this.idToIsExpandedMap.delete(questionId);
  }

  setAll(isExpanded: boolean) {
    this.idToIsExpandedMap.forEach((value, key, map) => map.set(key, isExpanded));
  }
}
