<template>
  <v-row>
    <v-col>
      <NumericInput
        v-model="question.minValue"
        :disabled="readonly"
        variant="outlined"
        label="Minimum"
        :minValue="-9999"
      />
    </v-col>
    <v-col>
      <NumericInput
        v-model="question.maxValue"
        :disabled="readonly"
        variant="outlined"
        label="Maksimum"
      />
    </v-col>
    <v-col>
      <NumericInput
        v-model="question.decimals"
        :disabled="readonly"
        variant="outlined"
        label="Maks. decimaler (blank = heltal)"
      />
    </v-col>
    <v-col>
      <v-checkbox
        v-model="inSumValidation"
        label="Andel af 100"
        persistent-hint
        hint="inSumValidation"
        :disabled="readonly"
      />
    </v-col>
  </v-row>
</template>
<script setup lang="ts">
import { NumericQuestionDefinition, NumericInput } from '@dims/components';
import { computed } from 'vue';

const { question, readonly = false } = defineProps<{
  question: NumericQuestionDefinition,
  readonly?: boolean,
}>();

const inSumValidation = computed({
  get() { return question.validation?.some((rule) => rule.rule === 'Sum100') ?? false; },
  set(val: boolean) {
    const hasRule = question.validation?.some((rule) => rule.rule === 'Sum100');
    if (hasRule && !val) {
      question.validation = question.validation?.filter((rule) => rule.rule !== 'Sum100');
    } else if (!hasRule && !val) {
      question.validation = [...(question.validation ?? []), { rule: 'Sum100' }];
    }
  },
});

</script>
