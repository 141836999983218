<template>
  <v-app class="app">
    <WithErrorPopup>
      <v-container fluid class="pa-6 bg-canvas">
        <TopNavBar />
        <v-main class="pt-10">
          <router-view />
        </v-main>
      </v-container>
    </WithErrorPopup>
  </v-app>
</template>

<script setup lang="ts">
import { useBackend, WithErrorPopup } from '@dims/components';
import TopNavBar from './components/nav-bars/TopNavBar.vue';
import { useStore } from './store/store';

const store = useStore();

async function load() {
  await checkLogin();
}
void load();

async function checkLogin() {
  /*
      Ensure user is logged in, and we have the user object in the store.
      If user is not logged in or token is expired, we redirect to login route.
    */
  if (store.user == null) {
    const backend = useBackend();
    console.log('store.user is null. Check if user is logged in ...');
    if (await (backend.userService.getUser()) == null) {
      // console.info('Redirect to login');
      // void router.push({ name: 'Login' });
      return;
    }
    await store.ensureInitialized();
  }
}

</script>
