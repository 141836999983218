import { SectionQuestionDefinition, VariantFieldType } from '@dims/components';

/** Get sub-question of the specified variant  */
export function getOptionalVariantQuestion(section: SectionQuestionDefinition, variant: VariantFieldType) {
  return section.questions?.find((q) => q.variant === variant);
}

export function getVariantQuestion(section: SectionQuestionDefinition, variant: VariantFieldType) {
  const question = getOptionalVariantQuestion(section, variant);
  if (!question) {
    throw new Error(`Variant '${variant}' not found`);
  }
  return question;
}
