<template>
  <v-dialog>
    <v-card :title="getTitle()">
      <v-card-text>
        <div v-for="(removal, index) in removedQuestions" :key="index">
          <h3 class="mt-5">{{ `${removal.previousValue} (${removal.questionDefinitionId})` }}
            <v-tooltip>
              <template #activator="{ props }">
                <v-chip
                  v-if="removalIsAddedQuestion(removal)"
                  class="mr-2 text-white"
                  color="red"
                  variant="flat"
                  size="small"
                  v-bind="props"
                >
                  <v-icon color="white">
                    mdi-plus
                  </v-icon>
                </v-chip>
              </template>
              <span>Spørgsmål også tilføjet i denne version</span>
            </v-tooltip>
          </h3>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">

import { computed } from 'vue';
import { QuestionDefinition } from '@dims/components';
import { QuestionDefinitionChange } from '../DeepQuestionnaireTemplateWithChanges';
import { getLastChanges } from '@/utils';

const { question, templateChanges } = defineProps<{
  question: QuestionDefinition;
  templateChanges: QuestionDefinitionChange[];
}>();

const removedQuestions = computed(() => getLastChanges(question, templateChanges, 'QuestionRemoved'));
const addedQuestions = computed(() => templateChanges.filter((tc) => tc.rootQuestionDefinitionId === question.questionDefinitionId && tc.change === 'QuestionAdded'));

function getTitle() {
  return `${removedQuestions.value.length} underliggende spørgsmål slettet under '${question.title}'`;
}

function removalIsAddedQuestion(removal: QuestionDefinitionChange) {
  if (addedQuestions.value.filter((q) => q.questionDefinitionId === removal.questionDefinitionId).length > 0) {
    return true;
  }

  return false;
}

</script>

<style scoped>

.previous-value-box {
  border: 1px solid black;
  padding: 10px;
}

</style>
