<template>
  <v-card
    class="fill-height"
    min-height="150"
    color="canvas"
    @click="emit('cardClick', card)"
  >
    <v-card-text>
      <div class="d-flex justify-space-between mb-3">
        <h2 class="mt-0">{{ card.title }}</h2>
        <v-btn color="lightPurple" :icon="card.icon" density="compact" :data-cy="card.title" />
      </div>
      <small>{{ card.description }}</small>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">

import { QuestionTypeCard } from '@/models';

const emit = defineEmits<{ cardClick: [QuestionTypeCard] }>();
defineProps<{ card: QuestionTypeCard }>();

</script>
