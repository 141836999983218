<template>
  <v-switch
    v-model="selected"
    @update:model-value="onChange"
    label="Obligatorisk at tage stilling til spørgsmålet"
    persistent-hint
    color="primary"
    hint="Dette betyder at spørgsmålet skal besvares med enten ja eller nej, før at kravet er udfyldt."
    :disabled="readonly"
  />
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';

const emit = defineEmits<{ changeToRadioButtons: [boolean | null] }>();
const { value = false, readonly = false } = defineProps<{
  value?: boolean,
  readonly?: boolean,
}>();
const selected = ref(false);

onMounted(() => {
  selected.value = value;
});
function onChange(newValue: boolean | null) {
  emit('changeToRadioButtons', newValue);
}

</script>
