<template>
  <draggable
    v-bind="getOptions"
    :list="questions ?? []"
    @start="drag = true"
    @end="end(); drag = false"
    item-key="questionDefinitionId"
  >
    <template #item="{ element: question }">
      <div v-if="(!showChangesOnly || getLastChanges(question, templateChanges)?.length)">
        <div
          class="d-flex align-center item"
          :class="{ 'bg-secondary text-white': isRoot(question) }"
          :style="isRoot(question) ? 'border: 0.5px solid black !important' : ''"
          :data-cy="isRoot(question) ? 'rootele' : 'subele'"
        >
          <QuestionToggle
            :expandedStateKeeper
            :isRoot="isRoot(question)"
            :question
            data-cy="togglebtn"
          />
          <span v-if="!isRoot(question)" class="mx-2">{{ indexInMainSection(question) }}</span>
          {{ question.title }}
          <v-chip v-if="question.questionType === 'Repeater'" class="ml-2" variant="flat" color="rebeccaPurple" size="small"><v-icon color="white">mdi-repeat</v-icon></v-chip>
          <v-chip v-if="showQuestionTypes" variant="flat" color="lightPurple" size="small" class="mx-2">
            {{ labelForQuestionType(question.questionType) }}
            {{
              (question.variant && question.variant !== 'supplier-readonly-repeater') ? ` / ${labelForVariant(question.variant)}` : ''
            }}</v-chip>
          <div v-if="showAccess">
            <!-- Icon showing customer visibility -->
            <v-tooltip v-if="question.accessibleForCustomer">
              <template #activator="{ props }">
                <v-chip
                  class="mr-2 text-white"
                  color="primary"
                  variant="flat"
                  size="small"
                  v-bind="props"
                >
                  <v-icon color="white">
                    mdi-eye-outline
                  </v-icon>
                </v-chip>
              </template>
              <span class="text-white">
                Dette spørgsmål vises til kunden
              </span>
            </v-tooltip>
            <v-tooltip v-else>
              <template #activator="{ props }">
                <v-chip
                  class="mr-2 text-white"
                  color="grey"
                  variant="flat"
                  size="small"
                  v-bind="props"
                >
                  <v-icon color="white">
                    mdi-eye-off-outline
                  </v-icon>
                </v-chip>
              </template>
              <span class="text-white">
                Dette spørgsmål vises ikke til kunden
              </span>
            </v-tooltip>
            <!-- Icon showing supplier visibility -->
            <v-tooltip v-if="question.accessForSupplier === 'None'">
              <template #activator="{ props }">
                <v-chip
                  class="mr-2 text-white"
                  color="grey"
                  variant="flat"
                  size="small"
                  v-bind="props"
                >
                  <v-icon color="white">
                    mdi-eye-off
                  </v-icon>
                </v-chip>
              </template>
              <span class="text-white">
                Dette spørgsmål vises ikke til leverandøren
              </span>
            </v-tooltip>
            <v-tooltip v-else-if="question.accessForSupplier === 'ReadOnly'">
              <template #activator="{ props }">
                <v-chip
                  class="mr-2 text-white"
                  color="grey"
                  variant="flat"
                  size="small"
                  v-bind="props"
                >
                  <v-icon color="white">
                    mdi-eye
                  </v-icon>
                </v-chip>
              </template>
              <span class="text-white">
                Dette spørgsmål vises readonly til leverandøren
              </span>
            </v-tooltip>
            <v-tooltip v-else-if="question.accessForSupplier === 'ReadWrite'">
              <template #activator="{ props }">
                <v-chip
                  class="mr-2 text-white"
                  color="primary"
                  variant="flat"
                  size="small"
                  v-bind="props"
                >
                  <v-icon color="white">
                    mdi-eye
                  </v-icon>
                </v-chip>
              </template>
              <span class="text-white">
                Dette spørgsmål vises read-write til leverandøren
              </span>
            </v-tooltip>
          </div>
          <!-- Dependencies -->
          <div
            v-if="showDependencies && question.dependsOn && question.dependsOn.operands.length > 0"
            class="dependency-block"
          >
            <div class="pr-1">Betinget af:</div>
            <div v-for="(dep, index) in question.dependsOn.operands" :key="dep" class="dependency pr-1">
              <span class="text-black font-weight-bold" v-if="index > 0">+</span>
              <v-tooltip location="top" max-width="500px">
                <template #activator="{ props }">
                  <span class="text-accent" v-bind="props">
                    {{ question.dependsOn.operator === 'Not' ? ' (!)' : '' }}{{ dependencyTitleShort(dependencyTitle(dep)) }}
                  </span>
                </template>
                <span class="text-white">
                  {{ question.dependsOn.operator === 'Not' ? '(!) ' : '' }}{{ dependencyTitle(dep) }}
                </span>
              </v-tooltip>
            </div>
          </div>
          <v-chip
            v-if="question.questionType === 'FileDownload' && question.attachments"
            size="small"
            class="mr-2"
          >
            Filer: {{ question.attachments.length }}</v-chip>
          <v-chip v-if="showIds" size="small">
            ID: {{ question.questionDefinitionId }}
            <CopyToClipboard :text="question.questionDefinitionId" />
            <span v-if="question.key">Key: {{ question.key }}</span>
          </v-chip>
          <div v-if="showChanges && getLastChangesOnQuestion(question, templateChanges)?.length > 0">
            <v-tooltip>
              <template #activator="{ props }">
                <v-badge
                  :content="getLastChangesOnQuestion(question, templateChanges).length"
                  color="blue"
                  :offset-x="8"
                >
                  <v-chip
                    class="mr-2 text-white"
                    color="red"
                    variant="flat"
                    size="small"
                    v-bind="props"
                  >
                    <v-icon color="white" @click="emit('questionChanges', question)" v-if="isQuestionChangeState(question, 'QuestionAdded')">
                      mdi-plus
                    </v-icon>
                    <v-icon color="white" @click="emit('questionChanges', question)" v-else>
                      mdi-radar
                    </v-icon>
                  </v-chip>
                </v-badge>
              </template>
              <span class="text-white" v-if="isQuestionChangeState(question, 'QuestionAdded')">
                Spørgsmål tilføjet{{ getLastChangedByText(question, templateChanges) }}
              </span>
              <span class="text-white" v-else>
                {{ `Spørgsmål har ${getLastChangesCountText(getLastChangesOnQuestion(question, templateChanges).length)} (klik for at se ændringer)` }}
              </span>
            </v-tooltip>
          </div>
          <div v-if="showChanges && hasSubquestionChanges(question, templateChanges) && !question.variant">
            <v-tooltip>
              <template #activator="{ props }">
                <v-chip
                  class="mr-2 text-white"
                  color="blue"
                  variant="flat"
                  size="small"
                  v-bind="props"
                >
                  <v-icon color="white">
                    mdi-radar
                  </v-icon>
                </v-chip>
              </template>
              <span class="text-white">
                Underliggende spørgsmål har ændringer
              </span>
            </v-tooltip>
          </div>
          <div v-if="showChanges && getLastChanges(question, templateChanges, 'QuestionRemoved').length > 0">
            <v-tooltip>
              <template #activator="{ props }">
                <v-badge
                  :content="getLastChanges(question, templateChanges, 'QuestionRemoved').length"
                  color="blue"
                  :offset-x="8"
                >
                  <v-chip
                    class="mr-2 text-white"
                    color="red"
                    variant="flat"
                    size="small"
                    v-bind="props"
                  >
                    <v-icon color="white" @click="emit('questionsDeleted', question)">
                      mdi-minus
                    </v-icon>
                  </v-chip>
                </v-badge>
              </template>
              <span class="text-white">
                {{ `${getLastChanges(question, templateChanges, 'QuestionRemoved').length} underliggende spørgsmål slettet` }}
              </span>
            </v-tooltip>
          </div>
          <span class="ml-auto">
            <!-- actions -->
            <span class="actions">
              <template v-if="readonly">
                <!-- view as read-only -->
                <v-icon @click="emit('viewQuestion', question)" title="Vis" color="black" icon="mdi-file-outline" />
              </template>
              <template v-if="!readonly && !isLoading">
                <!-- Ryk ud -->
                <v-icon v-show="canMoveOut(question)" @click="moveOut(question)" title="Ryk ud" color="black">mdi-arrow-left-thick</v-icon>
                <!-- Ryk ned -->
                <v-icon v-show="canMoveDown(question)" @click="moveDown(question)" title="Ryk ned" color="black">mdi-arrow-down-thick</v-icon>
                <!-- Ryk op -->
                <v-icon v-show="canMoveUp(question)" @click="moveUp(question)" title="Ryk op" color="black">mdi-arrow-up-thick</v-icon>
                <!-- Ryk ind -->
                <v-icon v-show="canMoveIn(question)" @click="moveIn(question)" title="Ryk ind" color="black">mdi-arrow-right-thick</v-icon>
                <!-- copy question -->
                <v-icon @click="emit('copyQuestion', question)" title="Kopier" color="black" icon="mdi-content-copy" data-cy="copybtn" class="ml-4" />
                <!-- edit -->
                <v-icon @click="emit('editQuestion', question)" title="Rediger" color="black" icon="mdi-pencil" data-cy="editbtn" />
                <!-- add new question -->
                <v-icon
                  v-if="isQuestionAccessEditable(question)"
                  @click="emit('addQuestion', question)"
                  title="Opret nyt spørgsmål"
                  color="black"
                  icon="mdi-plus-thick"
                  data-cy="addbtn"
                />
                <!-- delete -->
                <v-icon class="ml-1" v-if="canDelete(question)" @click="emit('deleteQuestion', question)" color="black" icon="mdi-delete" title="Slet" data-cy="deletebtn" />
                <v-icon class="ml-1" v-else color="disabled" icon="mdi-delete" data-cy="deletebtn-disabled" :title="cannotDeleteReason(question)" />
              </template>
            </span>
          </span>
        </div>
        <TemplateTreeView
          v-if="question.questions && isExpanded(question.questionDefinitionId) && isQuestionAccessEditable(question) && (!showChangesOnly || getLastChanges(question, templateChanges)?.length)"
          class="item-sub"
          :template
          :templateChanges
          :existingNotes
          :questions="question.questions"
          :showIds
          :showAccess
          :showQuestionTypes
          :showDependencies
          :showChanges
          :showChangesOnly
          :locator
          :expandedStateKeeper
          :readonly
          @viewQuestion="emit('viewQuestion', $event)"
          @addQuestion="emit('addQuestion', $event)"
          @editQuestion="emit('editQuestion', $event)"
          @deleteQuestion="emit('deleteQuestion', $event)"
          @questionChanges="emit('questionChanges', $event)"
          @questionsDeleted="emit('questionsDeleted', $event)"
          @copyQuestion="emit('copyQuestion', $event)"
          @updateTemplate="emit('updateTemplate', $event)"
          :isLoading
        />
      </div>
    </template>
  </draggable>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import {
  DeepQuestionnaireTemplate,
  QuestionDefinition,
  QuestionTypeName,
  VariantFieldType,
  VariantType,
} from '@dims/components';
import draggable from 'vuedraggable';
import { labelForQuestionTypeEnum, labelForVariantsEnum } from '@/models';
import QuestionToggle from '@/components/Templates/Editor/QuestionToggle.vue';
import QuestionLocator from './QuestionLocator';
import ExpandedStateKeeper from './ExpandedStateKeeper';
import { pricepointQuestions, getLastChanges, getLastChangesCountText, getLastChangedByText, getLastChangesOnQuestion, hasSubquestionChanges, isQuestionAccessEditable } from '@/utils';
import CopyToClipboard from '@/components/shared/CopyToClipboard.vue';
import { QuestionDefinitionChange } from '../DeepQuestionnaireTemplateWithChanges';
import { DeepQuestionnaireTemplateNote } from '@/models/DeepQuestionnaireTemplateNote';

/*
  vuedraggable doc:
  https://github.com/SortableJS/vue.draggable.next
*/

const emit = defineEmits<{
  viewQuestion: [QuestionDefinition],
  addQuestion: [QuestionDefinition],
  editQuestion: [QuestionDefinition],
  copyQuestion: [QuestionDefinition],
  deleteQuestion: [QuestionDefinition],
  questionChanges: [QuestionDefinition],
  questionsDeleted: [QuestionDefinition],
  updateTemplate: [DeepQuestionnaireTemplate],
}>();
const { template,
  templateChanges,
  existingNotes = undefined,
  questions, showIds = false,
  showChanges = true,
  showAccess = true,
  showChangesOnly = false,
  showQuestionTypes = false,
  showDependencies = false,
  readonly = false,
  locator,
  expandedStateKeeper,
  isLoading } = defineProps<{
  template: DeepQuestionnaireTemplate,
  templateChanges: QuestionDefinitionChange[],
  existingNotes?: DeepQuestionnaireTemplateNote[],
  questions: QuestionDefinition[],
  showIds?: boolean,
  showAccess?: boolean,
  showQuestionTypes?: boolean,
  showDependencies?: boolean,
  showChanges?: boolean,
  showChangesOnly?: boolean,
  readonly?: boolean,
  locator: QuestionLocator,
  expandedStateKeeper: ExpandedStateKeeper,
  isLoading: boolean,
}>();

const drag = ref(false);

function isExpanded(questionId: string) {
  return expandedStateKeeper.getIsExpanded(questionId);
}

function indexInMainSection(question: QuestionDefinition) {
  return locator.getQuestionViewModel(question)?.indexInMainSection;
}

// vue-draggable options
function getOptions() {
  return {
    animation: 150,
    group: 'description',
    ghostClass: 'ghost',
    disabled: readonly,
  };
}

function end() {
  emit('updateTemplate', template);
}

function dependencyTitle(id: string) {
  const dependency = locator.dependencyDisplayById(id);
  if (!dependency) {
    // Can happen if the dependent question have been deleted from the questionnaire
    console.error('Missing dependency ID:', id);
    return 'MANGLER';
  }
  return dependency.displayTitle;
}

function dependencyTitleShort(title: string) {
  return title.indexOf(':') > 0 ? title.split(':')[1] : title;
}

function isRoot(question: QuestionDefinition) {
  return template.questions.some(
    (q) => q.questionDefinitionId === question.questionDefinitionId,
  );
}

function labelForVariant(type: VariantType | VariantFieldType) {
  return labelForVariantsEnum(type as VariantType);
}

function labelForQuestionType(type: QuestionTypeName) {
  return labelForQuestionTypeEnum(type);
}

function isQuestionChangeState(question: QuestionDefinition, state: string) {
  const lastChanges = getLastChanges(question, templateChanges);

  return lastChanges.some((change) => change.change === state);
}

const pricePointQuestions = computed(() => pricepointQuestions(template.questions));

function canDelete(question: QuestionDefinition) {
  if (question.questionType === 'Intervals') {
    // Check if this questionId is used in any pricePointSet intervalQuestionIds
    return !(pricePointQuestions.value.some((q) => q.pricePointSet?.intervalsQuestionId === question.questionDefinitionId));
  }
  return !question.key;
}

function cannotDeleteReason(question: QuestionDefinition) {
  if (question.key) {
    return 'Kan ikke slette spørgsmål med backend nøgle.';
  }
  if (question.questionType === 'Intervals') {
    return 'Kan ikke slette intervalspørgsmål, da det bruges på et eller flere prispunkter.';
  }
  return '';
}

function canMoveDown(question: QuestionDefinition) {
  const questionViewModel = locator.getQuestionViewModel(question);
  if (questionViewModel === null) return false;
  if (!questionViewModel.isLast) return true;
  if (questionViewModel.parent) {
    const parentViewModel = locator.getQuestionViewModel(questionViewModel.parent);
    return parentViewModel && !parentViewModel.isLast;
  }
  return false;
}

function canMoveUp(question: QuestionDefinition) {
  const questionViewModel = locator.getQuestionViewModel(question);
  if (questionViewModel === null) return false;
  if (questionViewModel.index > 0) return true;
  if (questionViewModel.parent) {
    const parentViewModel = locator.getQuestionViewModel(questionViewModel.parent);
    return parentViewModel && parentViewModel.index > 0;
  }
  return false;
}

function canMoveOut(question: QuestionDefinition) {
  const questionViewModel = locator.getQuestionViewModel(question);
  if (questionViewModel === null) return false;
  return questionViewModel.level > 1 || (questionViewModel.level === 1 && question.questionType === 'Section' && !question.variant);
}

function canMoveIn(question: QuestionDefinition) {
  const questionViewModel = locator.getQuestionViewModel(question);
  if (questionViewModel === null) return false;
  if (!questionViewModel.precedingSibling) return false;
  const { precedingSibling } = questionViewModel;
  return !precedingSibling.variant;
}

function move(question: QuestionDefinition, sourceQuestions: QuestionDefinition[], targetQuestions: QuestionDefinition[], targetIndex: number) {
  const questionViewModel = locator.getQuestionViewModel(question);
  if (questionViewModel === null) return;
  const sourceIndex = questionViewModel.index;
  sourceQuestions.splice(sourceIndex, 1);
  targetQuestions.splice(targetIndex, 0, question);
  end();
}

function moveDown(question: QuestionDefinition) {
  if (!canMoveDown(question)) return;
  const questionViewModel = locator.getQuestionViewModel(question);
  if (questionViewModel === null) return;
  if (!questionViewModel.isLast) {
    move(question, questions, questions, questionViewModel.index + 1);
  } else if (questionViewModel.parent) {
    const parentViewModel = locator.getQuestionViewModel(questionViewModel.parent);
    if (parentViewModel?.followingSibling) {
      const parentFollowingSibling = locator.getQuestion(parentViewModel.followingSibling.questionDefinitionId);
      if (parentFollowingSibling.questions) {
        move(question, questions, parentFollowingSibling.questions, 0);
        expandedStateKeeper.setIsExpanded(parentFollowingSibling.questionDefinitionId, true);
      }
    }
  }
}

function moveUp(question: QuestionDefinition) {
  if (!canMoveUp(question)) return;
  const questionViewModel = locator.getQuestionViewModel(question);
  if (questionViewModel === null) return;
  if (questionViewModel.index > 0) {
    move(question, questions, questions, questionViewModel.index - 1);
  } else if (questionViewModel.parent) {
    const parentViewModel = locator.getQuestionViewModel(questionViewModel.parent);
    if (parentViewModel?.precedingSibling) {
      const parentPrecedingSibling = locator.getQuestion(parentViewModel.precedingSibling.questionDefinitionId);
      if (parentPrecedingSibling.questions) {
        const targetIndex = parentPrecedingSibling.questions.length;
        move(question, questions, parentPrecedingSibling.questions, targetIndex);
        expandedStateKeeper.setIsExpanded(parentPrecedingSibling.questionDefinitionId, true);
      }
    }
  }
}

function moveOut(question: QuestionDefinition) {
  if (!canMoveOut(question)) return;
  const questionViewModel = locator.getQuestionViewModel(question);
  if (questionViewModel === null) return;
  if (!questionViewModel.parent) return;
  const parentViewModel = locator.getQuestionViewModel(questionViewModel.parent);
  let targetQuestions: QuestionDefinition[] | null = null;
  if (parentViewModel?.parent) {
    const parentParent = locator.getQuestion(parentViewModel.parent.questionDefinitionId);
    if (parentParent.questions) {
      targetQuestions = parentParent.questions;
    }
  } else {
    targetQuestions = template.questions;
  }
  if (parentViewModel && targetQuestions) {
    move(question, questions, targetQuestions, parentViewModel.index + 1);
    if (questionViewModel.parent.questions?.length === 0) expandedStateKeeper.setIsExpanded(questionViewModel.parent.questionDefinitionId, false);
  }
}

function moveIn(question: QuestionDefinition) {
  if (!canMoveIn(question)) return;
  const questionViewModel = locator.getQuestionViewModel(question);
  if (questionViewModel === null) return;
  if (!questionViewModel.precedingSibling) return;
  const precedingSibling = locator.getQuestion(questionViewModel.precedingSibling.questionDefinitionId);
  if (precedingSibling.questions) {
    const targetIndex = precedingSibling.questions.length;
    move(question, questions, precedingSibling.questions, targetIndex);
    expandedStateKeeper.setIsExpanded(precedingSibling.questionDefinitionId, true);
  }
}

</script>

<style scoped>
.item {
  padding: 1rem;
  border: solid black 1px;
  background-color: white;
  cursor: move;
  font-family: arial, sans-serif;
}

.dependency-block {
  font-size: small;
  padding: 4px;
  background-color: gainsboro;
  border-radius: 8px;
  margin-right: 8px;
}

.dependency-block > div {
  display: inline-block;
  vertical-align: text-bottom;
}

.dependency {
  max-width: 20em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.item-sub {
  margin: 0 0 0 2rem;
}

* {
  color: rgb(var(--v-theme-primary));
  line-height: 1.2;
}

/* actions (add, edit, delete etc.) are hidden until hover, but still takes up space to avoid "jumping" */
.item .actions { display: flex;}

.item .actions i {
  visibility: hidden;
  padding: 4px; }
.item:hover .actions i { visibility: visible; }
</style>
