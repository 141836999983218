<template>
  <v-alert
    v-if="showAlert"
    :text
    :type
    style="overflow: visible"
  >
    <div v-if="showAlertButtons" style="padding-top: 5px;">
      <v-btn @click="buttonClickYes" class="primary-button-mini">Ja</v-btn>&nbsp;
      <v-btn @click="buttonClickNo" class="primary-button-mini">Nej</v-btn>
    </div>
  </v-alert>
</template>

<script setup lang="ts">
const emit = defineEmits<{ alertButtonClicked: [string|undefined] }>();

// const emit = defineEmits<{ result: boolean }>();
withDefaults(defineProps<{
  showAlert: boolean;
  text: string;
  type: 'success' | 'info' | 'warning' | 'error';
  showAlertButtons?: boolean;
}>(), {
  showAlertButtons: false,
});

function buttonClickYes() {
  emit('alertButtonClicked', 'yes');
}

function buttonClickNo() {
  emit('alertButtonClicked', 'no');
}

</script>
