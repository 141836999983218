<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="800px"
    @click:outside="closeDialog"
  >
    <template #activator="{ props }">
      <v-btn v-bind="props" class="secondary-button-mini mr-2">
        <v-icon size="small" class="mr-2">mdi-history</v-icon>
        Versionshistorik
      </v-btn>
    </template>
    <v-card>
      <template #title>
        <h3 class="mt-0">Versionshistorik</h3>
      </template>
      <template #append>
        <v-btn icon="mdi-close" @click="dialog = false" />
      </template>
      <v-card-text class="py-0">
        <v-divider class="my-0"></v-divider>
        <v-timeline v-if="backups.length > 0" density="compact">
          <v-timeline-item
            v-for="(backup, idx) in backups"
            :key="idx"
            size="small"
            :dot-color="idx === 0 ? 'accent' : 'grey'"
          >
            <v-row class="pt-1 align-center">
              <v-col>
                <div class="font-weight-bold">
                  {{ displayLastUpdated(backup) }}
                </div>
                <div class="text-caption">
                  <div>
                    {{ backup.lastUpdatedBy?.fullName }}
                  </div>
                  <small>{{ backup.id }}</small>
                </div>
              </v-col>
              <v-col cols="auto">
                <v-chip
                  v-if="idx === 0"
                >Aktive version</v-chip>
                <v-menu v-else>
                  <template #activator="{ props }">
                    <v-btn v-bind="props" dense icon="mdi-dots-vertical" />
                  </template>
                  <v-list>
                    <v-list-item @click="selectBackup(backup)">
                      <v-list-item-title>Skift til denne version</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="previewBackup(backup)">
                      <v-list-item-title>Preview version</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>
        <div v-else-if="backups.length === 0" class="py-2">
          Ingen tidligere versioner.
        </div>
        <div v-else>
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
        <v-dialog v-model="previewDialog">
          <v-card>
            <template #title>
              <h3 class="mt-0">Preview</h3>
            </template>
            <template #append>
              <v-toolbar color="transparent">
                <v-switch v-model="showQuestionTypes" class="mx-4" label="Vis typer" color="primary" inset></v-switch>
                <v-switch v-model="showIds" class="mx-4" label="Vis ID'er" color="primary" inset></v-switch>
                <v-switch v-model="showAccess" class="mx-4" label="Vis adgang" color="primary" inset></v-switch>
                <v-switch v-model="showDependencies" class="mx-4" label="Vis betingelser" color="primary" inset></v-switch>
                <v-btn icon="mdi-close" @click="previewDialog = false" />
              </v-toolbar>
            </template>
            <v-row class="ma-0 align-center justify-center">
              <v-divider class="my-0"></v-divider>
              <v-col>
                <TemplateBackupPreview
                  v-if="backups[0]"
                  :backup="backups[0]"
                  :template
                  :active="true"
                  :showIds
                  :showAccess
                  :showQuestionTypes
                  :showDependencies
                  :isLoading
                />
              </v-col>
              <v-col>
                <TemplateBackupPreview
                  v-if="selectedBackup && previewTemplateBackup"
                  :backup="selectedBackup"
                  :template="previewTemplateBackup"
                  :active="false"
                  :showIds
                  :showAccess
                  :showQuestionTypes
                  :showDependencies
                  @selectBackup="selectBackup"
                  :isLoading
                />
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { DeepQuestionnaireTemplate } from '@dims/components';
import { DateTime } from 'luxon';
import templateServices from '../../templateServices';
import { TemplateBackupHistoryEntry } from '../../TemplateBackupHistoryEntry';
import TemplateBackupPreview from './TemplateBackupPreview.vue';
import { useStore } from '@/store/store';

const emit = defineEmits<{ updateTemplate: [DeepQuestionnaireTemplate] }>();
const { template, isLoading } = defineProps<{
  template: DeepQuestionnaireTemplate,
  isLoading: boolean,
}>();
const previewTemplateBackup = ref<DeepQuestionnaireTemplate | null>(null);
const dialog = ref(false);
const previewDialog = ref(false);
const showIds = ref(false);
const showAccess = ref(true);
const showQuestionTypes = ref(true);
const showDependencies = ref(false);
const backups = ref<TemplateBackupHistoryEntry[]>([]);
const selectedBackup = ref<TemplateBackupHistoryEntry | null>(null);
const store = useStore();

watch(
  dialog,
  async () => {
    if (dialog.value) {
      await fetchTemplateBackups();
      dialog.value = true;
    }
  },
);

async function fetchTemplateBackups() {
  try {
    backups.value = await templateServices.getTemplateBackupHistory(
      template.id,
    );
  } catch (error) {
    store.showSnackbarError('Kunne ikke hente versioner', error);
  }
}

function closeDialog() {
  backups.value = [];
  dialog.value = false;
}

async function selectBackup(templateBackup: TemplateBackupHistoryEntry) {
  const backup = await templateServices.getTemplateBackup(
    templateBackup.id,
  );
  emit('updateTemplate', backup);
  closeDialog();
}

function displayLastUpdated(backup: TemplateBackupHistoryEntry) {
  const timestampMillis = backup.lastUpdated * 1000;
  const dateTime = DateTime.fromMillis(timestampMillis);
  return dateTime.toFormat('dd-MM-yyyy HH:mm');
}

async function previewBackup(templateBackup: TemplateBackupHistoryEntry) {
  selectedBackup.value = templateBackup;
  const backup = await templateServices.getTemplateBackup(templateBackup.id);
  previewTemplateBackup.value = backup;
  previewDialog.value = true;
}

</script>
