<template>
  <div class="container">
    <div v-if="!editing" class="overlay-message">
      <v-alert type="info" color="secondary" class="ma-0">
        Spørgsmålet skal gemmes først, før der kan tilføjes filer.
      </v-alert>
    </div>
    <div :class="{ overlay: !editing }">
      <v-data-table
        :headers
        :items="files"
        class="elevation-1 my-5"
        no-data-text="Ingen filer"
        no-results-text="Ingen filer"
        :items-per-page-options="[5, 10]"
        :disable-sort="!editing"
        :disable-filtering="!editing"
        :disable-pagination="!editing"
      >
        <template #top>
          <v-toolbar flat color="canvas">
            Filer
            <v-chip v-if="files" class="mx-2" size="small">{{ files.length }}</v-chip>
            <v-spacer></v-spacer>
            <v-file-input
              v-if="editing"
              :disabled="readonly"
              v-model="file"
              density="compact"
              label="Vedhæft fil"
              @change="onFilePicked"
              variant="outlined"
              hide-details
              :loading="isLoading"
            ></v-file-input>
          </v-toolbar>
        </template>
        <template v-if="editing" #[`item.actions`]="{ item }">
          <v-btn
            @click="deleteFile(item)"
            class="float-right"
            :disabled="isLoading || readonly"
            icon="mdi-delete"
          />
          <v-btn
            @click="download(item)"
            class="float-right mx-4"
            :disabled="isLoading"
            icon="mdi-download"
          />
          <v-checkbox
            v-model="item.isIncludedInPublishedMaterial"
            @update:model-value="onIsIncludedInPublishedMaterialUpdate(item)"
            class="float-right mt-1"
            density="compact"
            label="Indgår i offentliggjort materiale"
          />
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import {
  Attachment,
  DeepQuestionnaireTemplate,
  downloadFile,
  QuestionDefinition,
} from '@dims/components';
import templateServices from '@/components/Templates/templateServices';
import { isSKIFiles } from '../Templates/utilities';
import { useStore } from '@/store/store';

interface FileInfo {
  displayName: string;
  filename: string;
  isIncludedInPublishedMaterial: boolean;
}

const emit = defineEmits<{ attachmentsUpdated: [Attachment[]] }>();
const { template, question, editing = false, readonly = false } = defineProps<{
  template: DeepQuestionnaireTemplate,
  question: QuestionDefinition,
  editing?: boolean,
  readonly?: boolean }>();
const file = ref<File>();
const isLoading = ref(false);
const store = useStore();

const headers = [
  {
    title: 'Filnavn',
    value: 'displayName',
  },
  {
    title: '',
    value: 'actions',
  },
];

const files = computed((): FileInfo[] => {
  if (isSKIFiles(question)) {
    const { attachments } = question;
    return attachments?.map((attachment) => ({
      ...attachment,
    })) ?? [];
  }
  return [];
});

// This is necessary because the updated file in the v-data-table is an item in files, which is a computed property.
function onIsIncludedInPublishedMaterialUpdate(updatedFile: FileInfo) {
  if (isSKIFiles(question)) {
    const { attachments } = question;
    if (attachments) {
      // The filename works as unique id for attachments.
      const updatedAttachment = question.attachments?.find((attachment) => attachment.filename === updatedFile.filename);
      if (updatedAttachment) {
        updatedAttachment.isIncludedInPublishedMaterial = updatedFile.isIncludedInPublishedMaterial;
      }
    }
  }
}

async function onFilePicked(): Promise<void> {
  try {
    isLoading.value = true;
    if (!file.value) return;
    const attachments = await templateServices.uploadFile(
      template.id,
      question.questionDefinitionId,
      file.value,
    );
    emit('attachmentsUpdated', attachments);
  } catch (error) {
    store.showSnackbarError('Kunne ikke vedhæfte dokument', error);
  } finally {
    isLoading.value = false;
    file.value = undefined;
  }
}

async function download(fileDownload: FileInfo) {
  console.info(`download file: ${fileDownload.displayName}`);
  try {
    isLoading.value = true;
    const doc = await templateServices.downloadSKIFile(
      template.id,
      question.questionDefinitionId,
      fileDownload.displayName,
    );
    downloadFile(doc, fileDownload.displayName);
  } catch (e) {
    store.showSnackbarError('Kunne ikke hente dokument', e);
  } finally {
    isLoading.value = false;
  }
}

async function deleteFile(fileDelete: FileInfo) {
  console.info(`deleting file: ${fileDelete.displayName}`);
  try {
    isLoading.value = true;
    const attachments = await templateServices.deleteFile(
      template.id,
      question.questionDefinitionId,
      fileDelete.displayName,
    );
    emit('attachmentsUpdated', attachments);
  } catch (e) {
    store.showSnackbarError('Kunne ikke slette dokument', e);
  } finally {
    isLoading.value = false;
  }
}

</script>
<style scoped>
.container {
  position: relative;
}

.overlay {
  filter: blur(2px);
  background-color: #ccc;
  pointer-events: none;
}

.overlay-message {
  width: 50%;
  position: absolute;
  z-index: 99;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
</style>
