<template>
  <v-card class="mb-4">
    <template #title>
      <h3 class="mt-0">
        {{ wordTemplate.name }}
        <v-tooltip v-if="wordTemplate.splitUpRepeaterQuestionDefinitionId !== undefined">
          <template #activator="{ props: activatorProps }">
            <v-icon v-bind="activatorProps" color="black">mdi-repeat</v-icon>
          </template>
          <span>Et bilag pr. repeater item ({{ wordTemplate.splitUpRepeaterQuestionDefinitionId }})</span>
        </v-tooltip>
      </h3>
    </template>
    <div v-if="showIds">{{ wordTemplate.id }}</div>
    <div class="d-flex ml-4">
      <v-chip color="primary" v-for="(tag, ix) in wordTemplate.tags" :key="ix" class="mr-2">
        {{ tag }}
      </v-chip>
    </div>
    <v-card-text class="pt-0">
      <DependenciesDisplay
        :locator
        :condition="wordTemplate.dependsOn"
      />
      <div class="d-flex align-center">
        <v-btn class="secondary-button-mini" @click="downloadWordFile(wordTemplate)">
          Download skabelon
        </v-btn>
        <v-btn
          class="secondary-button-mini mx-4"
          @click="downloadWordDummy(wordTemplate)"
        >Download dummy</v-btn>
        <v-btn
          class="secondary-button-mini"
          @click="displayHtmlPreview(wordTemplate)"
        >Preview dummy</v-btn>
      </div>
    </v-card-text>
    <DocumentPreviewDialog
      :title="wordTemplate.name"
      :html="previewHtml"
    />
  </v-card>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import { downloadFile } from '@dims/components';
import { WordTemplate } from '@/models';
import DependenciesDisplay from './Editor/QuestionForm/DependenciesDisplay.vue';
import templateServices from './templateServices';
import QuestionLocator from './Editor/QuestionLocator';
import DocumentPreviewDialog from './Preview/DocumentPreviewDialog.vue';

defineProps<{
  wordTemplate: WordTemplate,
  locator: QuestionLocator,
  showIds: boolean,
}>();
const isLoading = ref(false);
const previewHtml = ref<string | null>(null);

async function downloadWordFile(wordTemplate: WordTemplate) {
  isLoading.value = true;
  const doc = await templateServices.downloadFile(wordTemplate);
  downloadFile(doc, `${wordTemplate.name}.docx`);
  isLoading.value = false;
}

async function downloadWordDummy(wordTemplate: WordTemplate) {
  isLoading.value = true;
  const blob = await templateServices.downloadWordDummy(wordTemplate);
  downloadFile(blob, wordTemplate.name);
  isLoading.value = false;
}

async function displayHtmlPreview(wordTemplate: WordTemplate) {
  isLoading.value = true;
  previewHtml.value = null;
  const html = await templateServices.getDummyWordHtmlPreview(wordTemplate);
  previewHtml.value = html;
  isLoading.value = false;
}

</script>
