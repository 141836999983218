<template>
  <v-dialog v-model="model" max-width="1400">
    <v-card class="pa-3" v-if="question" title="Kopier spørgsmål">
      <v-card-text>
        <div class="mt-3 d-flex align-center">
          <div>
            Vælg et spørgsmål som
            <span class="font-weight-bold text-accent">{{
              question.title
            }}</span>
            skal kopieres til eller kopier til yderste niveau.
          </div>
          <v-btn class="secondary-button-mini ml-2" data-cy="copytobtn" @click="copyQuestionToRoot()">Kopier til Niveau 1</v-btn>
        </div>
        <v-autocomplete
          v-model="copyViewItem"
          :items="copyViewItems"
          chips
          item-value="questionDefinitionId"
          item-title="displayTitle"
          variant="outlined"
          class="mt-3"
          return-object
          clearable
          persistent-hint
          density="compact"
        >
          <template #item="{ item, props }">

            <v-list-item v-bind="props">
              <template #title>
                {{ item.raw.displayTitle }}
              </template>

              <template #subtitle>
                <QuestionPath :path="item.raw.displayPath" />
              </template>

              <template #append>
                <v-chip v-bind="props" variant="flat" right size="small" color="lightPurple" :text="item.raw.questionType"></v-chip>
              </template>
            </v-list-item>
          </template>
        </v-autocomplete>
        <template v-if="copyViewItem">
          <v-alert
            density="compact"
            border="start"
            color="canvas"
            icon="mdi-information-outline"
            class="text-primary"
          >
            <QuestionPath
              :path="copyViewItem.displayPath"
              :newItem="question.title"
            />
          </v-alert>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="secondary-button"
          @click="model = false"
          :disabled="isLoading"
        >
          Annuller
        </v-btn>
        <v-btn
          class="primary-button"
          @click="copyQuestion()"
          :disabled="!copyViewItem || isLoading"
        >
          Kopier
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { DeepQuestionnaireTemplate, QuestionDefinition } from '@dims/components';
import { ref, computed } from 'vue';
import QuestionLocator, { CopyViewItem } from '../../QuestionLocator';
import QuestionPath from './QuestionPath.vue';
import ExpandedStateKeeper from '../../ExpandedStateKeeper';
import cloneQuestion from './cloneQuestion';

const emit = defineEmits<{ updateTemplate: [DeepQuestionnaireTemplate] }>();
const { template, question, locator, expandedStateKeeper } = defineProps<{
  template: DeepQuestionnaireTemplate,
  question: QuestionDefinition,
  locator: QuestionLocator,
  expandedStateKeeper: ExpandedStateKeeper,
}>();
const model = defineModel<boolean>();
const isLoading = ref(false);
const copyViewItem = ref<CopyViewItem | null>(null);
const clone = ref<QuestionDefinition | null>(null);

const copyViewItems = computed((): CopyViewItem[] => locator.copyDisplayList.filter((d) => !d.question.variant));

function copyQuestionToRoot() {
  clone.value = cloneQuestion(question);
  template.questions.push(clone.value);
  setIsExpandedForDescendants(clone.value, false);
  emit('updateTemplate', template);
  model.value = false;
}

function copyQuestion() {
  if (copyViewItem.value) {
    const destination = locator.getQuestion(copyViewItem.value.questionDefinitionId);
    if (destination.questions) {
      clone.value = cloneQuestion(question);
      destination.questions.push(clone.value);
      setIsExpandedForDescendants(clone.value, false);
      setIsExpandedForAncestors(destination, true);
      emit('updateTemplate', template);
    }
  }
  model.value = false;
}

function setIsExpandedForDescendants(question2: QuestionDefinition, isExpanded: boolean) {
  expandedStateKeeper.setIsExpanded(question2.questionDefinitionId, isExpanded);
  if (question2.questions) {
    question2.questions.forEach((child) => {
      setIsExpandedForDescendants(child, isExpanded);
    });
  }
}

function setIsExpandedForAncestors(question2: QuestionDefinition, isExpanded: boolean) {
  expandedStateKeeper.setIsExpanded(question2.questionDefinitionId, isExpanded);
  const questionViewModel = locator.getQuestionViewModel(question2);
  if (questionViewModel?.parent) {
    const parent = locator.getQuestion(questionViewModel.parent.questionDefinitionId);
    setIsExpandedForAncestors(parent, isExpanded);
  }
}

</script>
