<template>
  <v-container fluid>
    <h1 class="mb-5 font-weight-bold">
      Ikke fundet
    </h1>
    <p class="ml-5 mp-20 font-weight-thin text-body-2">
      Adressen {{ route.fullPath }} findes ikke.
    </p>
  </v-container>
</template>

<script setup lang="ts">

import { useRoute } from 'vue-router';

const route = useRoute();

</script>
