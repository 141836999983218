<template>
  <v-item-group>
    <v-container>
      <h2>{{ title }}</h2>
      <v-row class="mt-1">
        <v-col
          v-for="(card, idx) in cards"
          :key="idx"
          cols="12"
          lg="4"
          md="6"
          sm="12"
        >
          <QuestionCardItem
            :card
            @cardClick="emit('cardClick', $event)"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-item-group>
</template>

<script setup lang="ts">

import { QuestionTypeCard } from '@/models';
import QuestionCardItem from './QuestionCardItem.vue';

const emit = defineEmits<{ cardClick: [QuestionTypeCard] }>();
defineProps<{
  cards: QuestionTypeCard[],
  title: string,
}>();

</script>
